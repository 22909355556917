import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/hotel-image3.png";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Rajmahal Devgadh Baria</h1>
        </h1>
        <p>
        The family of Rajmahal Devgadh Baria originates from the Khichi
            Chauhan dynasty, descendants of Prithviraj Chauhan. Devgadh Baria
            town was establish ed in1524 by Maharawal Dungar Singh Ji grandson
            of Maharawal Patai Rawal, after escaping the conquest by Mohammad
            Begada in Pavagadh. Rest of the geneology can be followed on
            wikipedia.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="The Pearl 21 Villa Image"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
