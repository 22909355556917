import React from "react";
import { useNavigate } from "react-router-dom";
import "../css/explore-rooms-section.css";
import RoomCard from "./room-card";
import SunkenGardenSuite from "../assets/rooms/sunken-garden-suite/sunken-garden-suite.png"; // Add more images if needed
import MaharaniSuite from "../assets/rooms/maharani-suite/maharani-suite.png"; // Add more images if needed
import MaharajaSuite from "../assets/rooms/maharaja-suite/maharaja-suite.png"; // Add more images if needed
import RoyalSuite from "../assets/rooms/royal-suite/royal-suite.png"; // Add more images if needed

const ExploreRoomsSection = () => {
  const navigate = useNavigate();
  const rooms = [
    {
      id: 1,
      image: SunkenGardenSuite,
      title: "Sunken Garden Suite",
      description:
      "The Sunken Garden Suite at Rajmahal Devgadh Baria offers spacious, elegant interiors with direct access to a tranquil sunken garden. Combining heritage charm and modern luxury, it ensures a truly relaxing and best stay.",
      price: 11000 ,
    },
    {
      id: 2,
      image: MaharaniSuite,
      title: "Maharani Suite",
      description:
        "The Maharani Suite at Rajmahal Devgadh Baria exudes royal elegance, featuring opulent decor, spacious interiors, and a serene ambiance. A perfect blend of luxury and heritage, it offers a regal stay experience.",
      price: 6000,
    },
    {
      id: 3,
      image: MaharajaSuite,
      title: "Maharaja Suite",
      description:
        "The Maharaja Suite at Rajmahal Devgadh Baria epitomizes grandeur, with majestic interiors, vintage furnishings, and a palatial ambiance. Experience royal luxury and timeless charm in this exquisite suite.",
      price: 6000,
    },
    {
      id: 4,
      image: RoyalSuite,
      title: "Royal Suite",
      description:
        "The Royal Suite at Rajmahal Devgadh Baria combines timeless elegance with modern luxury, featuring sophisticated decor and spacious comfort. A perfect retreat for a regal and serene stay.",
      price: 6000,
    },
    
  ];

  return (
    <section className="explore-rooms-section">
      <div className="explore-rooms-header">
        <div className="explore-rooms-header-left swipe-left">
          <h2>Explore Our Rooms</h2>
          <h4>Choose a room according to your budget</h4>
        </div>
        <div className="explore-rooms-header-right swipe-right">
          <button
            onClick={() => {
              navigate("/rooms");
            }}
          >
            View All Rooms <i className="fa-solid fa-caret-right"></i>
          </button>
        </div>
      </div>
      <div className="explore-rooms-cards swipe">
        {rooms.map((room, index) => (
          <RoomCard
            key={index}
            image={room.image}
            title={room.title}
            description={room.description}
            price={room.price}
          />
        ))}
      </div>
    </section>
  );
};

export default ExploreRoomsSection;
