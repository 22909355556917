import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';
import SunkenGardenSuite from '../components/sunken-garden-suite';

const SunkenGardenSuitePage = () => {
    return (
        <>
            <Helmet>
    <title>Sunken Garden Suite Room - Rajmahal Devgadh Baria</title>
    <meta name="description" content="Stay in the luxurious Sunken Garden Suite Room at Rajmahal Devgadh Baria. Enjoy spacious interiors, garden views, and premium amenities in a royal heritage setting." />
    <meta name="keywords" content="Sunken Garden Suite Room, Rajmahal Devgadh Baria suite, luxury suite Devgadh Baria, garden view suite room, royal heritage hotel, Rajmahal premium rooms, Devgadh Baria accommodations" />
    <link rel="canonical" href="https://www.rajmahaldevgadhbaria.com/rooms/sunken-garden-suite" />

    {/* JSON-LD structured data */}
    <script type="application/ld+json">
        {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "HotelRoom",
            "name": "Sunken Garden Suite Room",
            "url": "https://www.rajmahaldevgadhbaria.com/rooms/sunken-garden-suite",
            "telephone": "+91 9971834111",
            
        })}
    </script>
</Helmet>


            <TopBanner />
            <Navbar />
            <SunkenGardenSuite/>
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default SunkenGardenSuitePage;
