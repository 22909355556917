import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';
import PlacesToVisit from '../components/places-to-visit';

const PlacesToVisitPage = () => {
    return (
        <>
            <Helmet>
                <title>Places to Visit - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Discover the best places to visit around Rajmahal Devgadh Baria. Explore local attractions, historical sites, and cultural landmarks in Gujarat. Plan your trip with our guide to must-see spots near Rajmahal." />
                <meta name="keywords" content="places to visit Rajmahal Devgadh Baria, tourist attractions Devgadh Baria, things to do near Rajmahal, historical sites Gujarat, Rajmahal sightseeing, Devgadh Baria travel guide, best places in Devgadh Baria, visit Gujarat, heritage sites near Rajmahal" />
                <link rel="canonical" href="https://rajmahaldevgadhbaria.com/places-to-visit" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Places to Visit - Rajmahal Devgadh Baria",
                        "telephone": "+91 9971834111",
                        "url": "https://rajmahaldevgadhbaria.com/places-to-visit"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <PlacesToVisit/>
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default PlacesToVisitPage;
