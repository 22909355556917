import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';
import MaharajaSuite from '../components/maharaja-suite';

const MaharajaSuitePage = () => {
    return (
        <>
            <Helmet>
                <title>Maharaja Suite - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Indulge in regal luxury at the Maharaja Suite of Rajmahal Devgadh Baria. Relish opulent interiors, exceptional amenities, and the grandeur of a royal heritage stay." />
                <meta name="keywords" content="Maharaja Suite, Rajmahal Devgadh Baria suite, luxury suite Devgadh Baria, royal heritage Maharaja Suite, premium hotel rooms Rajmahal, Devgadh Baria royal accommodation, luxury stay in Devgadh Baria" />
                <link rel="canonical" href="https://www.rajmahaldevgadhbaria.com/rooms/maharaja-suite" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "HotelRoom",
                        "name": "Maharaja Suite",
                        "url": "https://www.rajmahaldevgadhbaria.com/rooms/maharaja-suite",
                        "telephone": "+91 9971834111"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <MaharajaSuite />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default MaharajaSuitePage;
