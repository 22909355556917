import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import ExploreRoomsSection from '../components/explore-rooms-section';
import SocialMediaFixed from '../components/socialmedia-fixed';

const RoomsPage = () => {
    return (
        <>
            <Helmet>
                <title>Rooms - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Explore luxurious rooms at Rajmahal Devgadh Baria. Book your stay in our elegantly designed rooms with modern amenities for a royal and comfortable experience." />
                <meta name="keywords" content="Rajmahal Devgadh Baria rooms, luxury hotel rooms, book rooms Rajmahal, Devgadh Baria accommodation, Rajmahal room booking, royal hotel rooms, luxury stay in Devgadh Baria, hotel reservations Gujarat, Rajmahal hotel rooms details" />
                <link rel="canonical" href="https://rajmahaldevgadhbaria.com/rooms" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Hotel",
                        "name": "Rooms - Rajmahal Devgadh Baria",
                        "telephone": "+91 9971834111",
                        "url": "https://rajmahaldevgadhbaria.com/rooms"
                    })}
                </script>
            </Helmet>
            <TopBanner />
            <Navbar />
            <ExploreRoomsSection/>
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default RoomsPage;
