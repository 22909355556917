import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';
import RoyalSuite from '../components/royal-suite';

const RoyalSuitePage = () => {
    return (
        <>
            <Helmet>
                <title>Royal Suite - Rajmahal Devgadh Baria</title>
                <meta name="description" content="Experience unparalleled luxury in the Royal Suite at Rajmahal Devgadh Baria. Enjoy exquisite interiors, premium amenities, and a royal heritage ambiance." />
                <meta name="keywords" content="Royal Suite, Rajmahal Devgadh Baria suite, luxury accommodation Devgadh Baria, royal heritage suite, premium rooms Rajmahal, Devgadh Baria royal hotel, luxurious stay in Devgadh Baria" />
                <link rel="canonical" href="https://www.rajmahaldevgadhbaria.com/rooms/royal-suite" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "HotelRoom",
                        "name": "Royal Suite",
                        "url": "https://www.rajmahaldevgadhbaria.com/rooms/royal-suite",
                        "telephone": "+91 9971834111"
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <RoyalSuite />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default RoyalSuitePage;
