import React from "react";
import "../css/places-to-visit.css";

const PlacesToVisit = () => {
  return (
    <section className="places-to-visit-section">
      <h1 className="swipe">Places To Visit</h1>
      <br />
      <div className="places-to-visit-container">
        <div className="places-to-visit-card places-to-visit-card1 swipe">
          <div className="places-to-visit-card-content">
            <h2>RATAN MAHAL SANCTUARY</h2>
            <p>
            Ratanmahal Wildlife Sanctuary in Dahod, Gujarat, is home to the state's largest sloth bear population, set in rugged, hill-station-like forests. Established in 1982, it offers a unique wildlife experience near the Gujarat-Madhya Pradesh border.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card2 swipe">
          <div className="places-to-visit-card-content">
            <h2>UDHAL MAHUDA</h2>
            <p>

            Udhal Mahuda, part of the Ratanmahal Sloth Bear Sanctuary near Devgadh Baria in Gujarat, offers stunning views, including the Mira Waterfall, along with accommodations in tents and cottages for an immersive nature experience.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card3 swipe">
          <div className="places-to-visit-card-content">
            <h2>SAGTALA</h2>
            <p>
            Sagtala is a village in Devgad Bariya Taluka, Dahod District, Gujarat, located 55 km west of Dahod and surrounded by villages like Fangiya, Dabhva, and Navi Bedi.It lies near Chhota Udepur, Dhanpur, Ghoghamba, and Jambughoda Talukas.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card4 swipe">
          <div className="places-to-visit-card-content">
            <h2>DUDHANA FARM HOUSE</h2>
            <p>
            Dudhana is a serene and quaint cottage, beautifully surrounded by lush amla and lemon trees, offering a peaceful retreat in nature. Owned by Rajmahal Baria, it provides a perfect escape to unwind and experience the tranquility of countryside.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card5 swipe">
          <div className="places-to-visit-card-content">
            <h2>AVANTI - PRIVATE RESIDENCE</h2>
            <p>

            Avanti is a 20-acre lush forest retreat, originally a getaway spot for the Maharani's of Devgadh Baria. It was later converted into the royal residence, where the royal family lived until March 2013, before moving to Rajmahal.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlacesToVisit;
